import React, { useState } from "react"
import CaseStudyModule from "../modules/CaseStudyModule"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Button from "@components/ui/buttons/Button"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"

const CaseStudiesMixins = styled.div`
  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin-bottom: 81px;
  }

  #show_all_case_study {
    color: var(--color-text-light);
    margin: 5em auto 42px;
    font-weight: bold;
  }

  #case_container {
    display: flex;
    flex-wrap: wrap;

    .case-study-module {
      width: 100%;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        width: 50%;
      }
    }

    ${breakpointsMedia(breakpoints.tabletWide)} {
      &:not(.filtered) {
        .case-study-module:nth-child(even) {
          animation: offsetCaseStudy 1s 1s both;
        }
      }
    }
  }

  .hidden_cases {
    display: none;
    clear: both;

    .case-study-module {
      opacity: 0;

      -webkit-transition: opacity 1s;
      -moz-transition: opacity 1s;
      transition: opacity 1s;

      &.active {
        opacity: 1;
      }
    }
  }
`

const ClientCaseStudies = props => {
  const {
    allWpCaseStudy: { caseStudies },
  } = useStaticQuery(graphql`
    {
      allWpCaseStudy {
        caseStudies: nodes {
          uri
          title
          menuOrder
          featuredImage {
            node {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 609
                    height: 609
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          acfCaseStudyFeatured {
            blockContent
            whiteClientLogo {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 130
                    transformOptions: { cropFocus: CENTER }
                    layout: FIXED
                    placeholder: BLURRED
                  )
                }
              }
            }
            coverImage {
              id
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 609
                    height: 609
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const [showAll, setShowAll] = useState(false)

  const max_featured = 10

  return (
    <div id="clients_section">
      <div className="container">
        <CaseStudiesMixins>
          <Spacer>
            <Heading underlined>Our Case Studies</Heading>
          </Spacer>

          <div id="case_container">
            {caseStudies
              .sort((a, b) => a.menuOrder - b.menuOrder)
              .map((caseStudy, key) => {
                const caseData = {
                  ...caseStudy,
                  ...caseStudy.acfCaseStudyFeatured,
                }

                const isVisible = key + 1 <= max_featured || showAll

                return (
                  <CaseStudyModule
                    key={key}
                    isVisible={isVisible}
                    {...caseData}
                  />
                )
              })}
          </div>
          <Button
            color="orange"
            id="show_all_case_study"
            onClick={() => setShowAll(true)}
            style={{
              display: showAll ? "none" : "table",
            }}
          >
            {" "}
            <i className="fas fa-caret-down" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              className="svg-inline--fa fa-chevron-down fa-w-14"
              role="img"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              />
            </svg>
            Load more
          </Button>
        </CaseStudiesMixins>
      </div>
    </div>
  )
}

ClientCaseStudies.propTypes = {}

export default ClientCaseStudies
