import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import { GlobalClientLogoGallery } from "@components/sections/GlobalClientLogoGallery"
import ClientCaseStudies from "@components/sections/ClientCaseStudies"

export default function CaseStudiesPage({ data }) {
  const page = data.allWpPage.nodes[0]

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short",
        }}
      />

      <ClientCaseStudies />
      <GlobalClientLogoGallery />
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpPage(
    filter: {template: {templateName: {eq: "Clients"}}, slug: {eq: $slug}}
  ) {
    nodes {
      id
      uri
      title
      link
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
    }
  }
}
`
