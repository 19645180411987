import React, { useReducer } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import classNames from "classnames"
import PropTypes from "prop-types"
import GatsbyBGImage from "gatsby-background-image"
import { FontFamily } from "@styles/variables/FontFamily"

import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Colors from "@styles/variables/Colors"
import { convertToBgImage } from "gbimage-bridge"
import Heading from "@components/ui/Heading"

const StyledGatsbyBGImage = styled(props => <GatsbyBGImage {...props} />)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-color: var(--color-primary);

  .bg-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const CaseStudyLinkStyle = styled(props => <Link {...props} />)`
  height: 500px;
  width: 50%;
  color: var(--color-text-light);
  position: relative;
  text-decoration: none;
  display: block;
  background-color: var(--color-primary);

  @media (min-width: 768px) {
    height: 609px;
  }

  .read-more-con {
    background-color: transparent;
    padding: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: 768px) {
      padding: 41px;
    }

    .inner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s;
      padding: 0 29px;
      left: 0;
      width: 100%;

      @media (min-width: 768px) {
        padding: 0 41px;
      }
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .title {
    font-weight: 600;
    line-height: 44px;
    color: var(--color-text-light);
    margin-bottom: 12px;

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  ul.case-services {
    font-size: 18px;
    line-height: 25px;
    color: var(--color-text-light);
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 29px;
    }

    li {
      &:not(:last-child):after {
        content: "|";
        margin: 0 11px;
      }
    }
  }

  .logo-wrap {
    width: 100px;
    margin-left: 25px;

    @media (min-width: 768px) {
      width: 130px;
      margin-left: 33px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  .read-more {
    color: var(--color-text-light);
    font-family: ${FontFamily.Heading};
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;

    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    display: none;
    padding: 13px;
    padding-left: 28px;

    @media (min-width: 768px) {
      bottom: 40px;
    }

    i {
      color: var(--color-secondary);
      font-size: 1.2em;
      margin-right: 11px;
      position: absolute;
      left: 0;
      top: 6px;
      line-height: 46px;
    }

    span {
      text-decoration: underline;
    }

    .more {
    }

    .less {
      display: none;
    }
  }

  .excerpt {
    color: var(--color-text-light);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    height: 174px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 29px;
    padding-top: 9px;
    font-weight: 400;

    @media (min-width: 768px) {
      padding: 0 41px;
      padding-top: 9px;
    }

    &,
    p {
      font-size: 13px;
      line-height: 24px;

      @media (min-width: 768px) {
        font-size: 17px;
        line-height: 29px;
      }
    }
  }

  .no-touch &:hover,
  .touch &.open {
    .read-more-con {
      background-color: var(--color-primary);

      &.w-content .inner {
        top: 20%;

        @media (min-width: 768px) {
          margin-top: -122px;
          top: 50%;
        }

        @media (min-width: 1200px) {
          margin-top: -77px;
        }
      }
    }

    .excerpt {
      opacity: 1;
      visibility: visible;
    }

    .read-more {
      i {
        transform: rotate(180deg);
      }

      .more {
        display: none;
      }

      .less {
        display: inline-block;
      }
    }
  }

  .touch & {
    .read-more {
      display: block;
    }
  }
`

const CaseStudyModule = ({
  title,
  uri,
  blockContent,
  isVisible,
  coverImage,
  featuredImage,
  whiteClientLogo,
}) => {
  const [isOpen, toggle] = useReducer(isOpen => !isOpen, false)

  const bgImage = coverImage || featuredImage?.node

  const readMoreToggle = e => {
    e.preventDefault()
    e.stopPropagation()
    toggle()
  }

  const image =
    bgImage?.localFile?.childImageSharp &&
    convertToBgImage(bgImage.localFile.childImageSharp.gatsbyImageData)

  return (
    <CaseStudyLinkStyle
      to={uri}
      className={classNames("case-study-module", {
        open: isOpen,
      })}
      style={{
        display: isVisible ? "block" : "none",
      }}
    >
      <StyledGatsbyBGImage
        className="bg"
        {...image}
        backgroundColor={Colors.brandColour}
      >
        <div className="bg-overlay" />
      </StyledGatsbyBGImage>
      <div
        className={classNames("read-more-con", {
          "w-content": blockContent,
        })}
      >
        <div className="inner">
          <div className="title-wrap">
            <div>
              <Heading node="h2" underlined color="white">
                {title}
              </Heading>
            </div>
            <div className="logo-wrap">
              {whiteClientLogo?.localFile && (
                <CustomGatsbyImage {...whiteClientLogo.localFile} />
              )}
            </div>
          </div>
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: blockContent }}
          />
        </div>
        {blockContent && (
          <div
            className="read-more"
            onClick={readMoreToggle}
            onKeyDown={readMoreToggle}
            tabIndex={0}
            role="button"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-down"
              className="svg-inline--fa fa-angle-down fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
              ></path>
            </svg>
            <span className="more">Read more</span>
            <span className="less">Read less</span>
          </div>
        )}
      </div>
    </CaseStudyLinkStyle>
  )
}

CaseStudyModule.propTypes = {
  title: PropTypes.string.isRequired,
  blockContent: PropTypes.string,
  coverImage: PropTypes.object,
  whiteClientLogo: PropTypes.object,
  isVisible: PropTypes.bool,
}

CaseStudyModule.defaultProps = {
  isVisible: true,
}

export default CaseStudyModule
