import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { Link } from "gatsby"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Heading from "@components/ui/Heading"

const PortfolioGalleryStyled = styled.section`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 13px;
  justify-content: center;

  .portfolio-image {
    flex: 0 0 33.33%;
    padding: 27px 16px;
    text-align: center;
    filter: grayscale(100%);

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 39px 16px;
    }

    @media (min-width: 768px) {
      flex: 0 0 20%;
    }

    .image-wrap {
      width: 146px;
      margin: auto;

      svg {
        max-width: 100%;
        height: auto;
      }
    }

    &:hover {
      filter: grayscale(0%);
    }
  }
`

const ClientLogoGallery = ({ title, logos, addLink }) => {
  return (
    <div id="portfolio_container_gallery">
      <div className="container">
        <Heading node="h3">{title}</Heading>
        <PortfolioGalleryStyled id="portfolio_gallery">
          {logos.map((logo, key) => {
            const featuredImage = logo.featuredImage

            return (
              <a
                key={key}
                href={logo.clientUrl}
                className="portfolio-image "
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={100 * key}
                data-aos-anchor="#portfolio_gallery"
              >
                {featuredImage?.localFile && (
                  <div className="image-wrap">
                    <CustomGatsbyImage
                      {...featuredImage.localFile}
                      importSVG={true}
                    />
                  </div>
                )}
              </a>
            )
          })}
        </PortfolioGalleryStyled>
        {addLink && (
          <div
            className="text-center cta-wrap  "
            data-aos="fade"
            data-aos-duration="1000"
          >
            <Link to="/contact/" className="btn btn--lg pink">
              Work with us
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

ClientLogoGallery.propTypes = {
  title: PropTypes.string,
  addLink: PropTypes.bool,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      featuredImage: PropTypes.object,
      clientUrl: PropTypes.string,
    })
  ),
}

ClientLogoGallery.defaultProps = {
  title: `We work with`,
  addLink: true,
}

export default ClientLogoGallery
